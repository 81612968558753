import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide87/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide87/image/img.png"
import slider1 from "@components/pageGuide/guides/guide87/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide87/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide87/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Ecommerce",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo implementar notificaciones automatizadas de fidelización en programas de puntos
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      Las notificaciones automatizadas ayudan a mantener el interés de los clientes en un programa de puntos. Configura envíos según el comportamiento de cada usuario para recordar beneficios y oportunidades. Define mensajes claros que informen sobre acumulación y redención de puntos.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Configuración de recordatorios automáticos.",
      },
      {
        key: 2,
        text: "Notificaciones personalizadas sobre puntos."
      },
      {
        key: 3,
        text: "Envío de alertas automáticas sobre promociones.",
      },
      {
        key: 4,
        text: "Automatización de mensajes de nivel en el programa.",
      },
      {
        key: 5,
        text: "Segmentación de clientes para campañas de fidelización.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás saber cómo programar recordatorios en los momentos clave del ciclo del cliente. Asegúrate de incluir enlaces a detalles adicionales y opciones de canje.
          <br /><br />
          Por último, descubre más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Optimiza la comunicación y mejora la fidelización.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
