import React from "react"
import TemplatePageGuide from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide87/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
       Cómo implementar notificaciones automatizadas de fidelización en programas de puntos
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/fidelizacion-programas-puntos/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo implementar notificaciones automatizadas para mantener informados a los clientes sobre su acumulación y uso de puntos en tu programa de fidelización."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="  Cómo implementar notificaciones automatizadas de fidelización en programas de puntos"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/fidelizacion-programas-puntos/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia87.jpg`}
      />
      <meta
        property="og:description"
        content="Descubre cómo implementar notificaciones automatizadas para mantener informados a los clientes sobre su acumulación y uso de puntos en tu programa de fidelización."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Ecommerce "
      location={location}
      typeGuide="guide87"
      clickHereHref="https://beexcc.com/blog/"
      data={data}
    />
  </div>
)

export default IndexPage
